import LifNeuronVideo from "../../../../content/posts/2021-11-25-spiking-neural-networks/lif-neuron.mp4";
import LifResponse1Video from "../../../../content/posts/2021-11-25-spiking-neural-networks/lif-response-1.mp4";
import LifResponse2Video from "../../../../content/posts/2021-11-25-spiking-neural-networks/lif-response-2.mp4";
import LifResponse3Video from "../../../../content/posts/2021-11-25-spiking-neural-networks/lif-response-3.mp4";
import * as React from 'react';
export default {
  LifNeuronVideo,
  LifResponse1Video,
  LifResponse2Video,
  LifResponse3Video,
  React
};
import './src/styles/global.scss';
import "aos/dist/aos.css";


// const addMauticScript = () => {
//     if (typeof MauticSDKLoaded == 'undefined') {
//         var MauticSDKLoaded = true;
//         var head = document.getElementsByTagName('head')[0];
//         var script = document.createElement('script');
//         script.type = 'text/javascript';
//         script.src = 'https://mautic.theaisummer.com/media/js/mautic-form.js';
//         script.onload = function () {
//             MauticSDK.onLoad();
//         };
//         head.appendChild(script);
//         var MauticDomain = 'https://mautic.theaisummer.com';
//         var MauticLang = {
//             submittingMessage: 'Please wait...'
//         };
//     } else if (typeof MauticSDK != 'undefined') {
//         MauticSDK.onLoad();
//     }
// };

// export const onClientEntry = () => {
//     window.onload = () => {
//         addMauticScript();
//     };
// };

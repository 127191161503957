// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-books-courses-index-tsx": () => import("./../../../src/pages/books-courses/index.tsx" /* webpackChunkName: "component---src-pages-books-courses-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-deep-learning-in-production-book-index-tsx": () => import("./../../../src/pages/deep-learning-in-production-book/index.tsx" /* webpackChunkName: "component---src-pages-deep-learning-in-production-book-index-tsx" */),
  "component---src-pages-deep-reinforcement-learning-course-index-tsx": () => import("./../../../src/pages/deep_reinforcement_learning_course/index.tsx" /* webpackChunkName: "component---src-pages-deep-reinforcement-learning-course-index-tsx" */),
  "component---src-pages-gans-computer-vision-ebook-index-tsx": () => import("./../../../src/pages/gans-computer-vision-ebook/index.tsx" /* webpackChunkName: "component---src-pages-gans-computer-vision-ebook-index-tsx" */),
  "component---src-pages-get-started-with-machine-learning-index-tsx": () => import("./../../../src/pages/get_started_with_machine_learning/index.tsx" /* webpackChunkName: "component---src-pages-get-started-with-machine-learning-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-introduction-to-deep-learning-course-index-tsx": () => import("./../../../src/pages/introduction-to-deep-learning-course/index.tsx" /* webpackChunkName: "component---src-pages-introduction-to-deep-learning-course-index-tsx" */),
  "component---src-pages-learn-ai-advanced-tsx": () => import("./../../../src/pages/learn-ai/advanced.tsx" /* webpackChunkName: "component---src-pages-learn-ai-advanced-tsx" */),
  "component---src-pages-learn-ai-fundamentals-tsx": () => import("./../../../src/pages/learn-ai/fundamentals.tsx" /* webpackChunkName: "component---src-pages-learn-ai-fundamentals-tsx" */),
  "component---src-pages-learn-ai-index-tsx": () => import("./../../../src/pages/learn-ai/index.tsx" /* webpackChunkName: "component---src-pages-learn-ai-index-tsx" */),
  "component---src-pages-learn-ai-software-tsx": () => import("./../../../src/pages/learn-ai/software.tsx" /* webpackChunkName: "component---src-pages-learn-ai-software-tsx" */),
  "component---src-pages-newsletter-index-tsx": () => import("./../../../src/pages/newsletter/index.tsx" /* webpackChunkName: "component---src-pages-newsletter-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy_policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-resources-index-tsx": () => import("./../../../src/pages/resources/index.tsx" /* webpackChunkName: "component---src-pages-resources-index-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-pages-start-here-index-tsx": () => import("./../../../src/pages/start-here/index.tsx" /* webpackChunkName: "component---src-pages-start-here-index-tsx" */),
  "component---src-pages-support-index-tsx": () => import("./../../../src/pages/support/index.tsx" /* webpackChunkName: "component---src-pages-support-index-tsx" */),
  "component---src-templates-author-page-index-tsx": () => import("./../../../src/templates/AuthorPage/index.tsx" /* webpackChunkName: "component---src-templates-author-page-index-tsx" */),
  "component---src-templates-blog-page-index-tsx": () => import("./../../../src/templates/BlogPage/index.tsx" /* webpackChunkName: "component---src-templates-blog-page-index-tsx" */),
  "component---src-templates-markdown-page-index-tsx": () => import("./../../../src/templates/MarkdownPage/index.tsx" /* webpackChunkName: "component---src-templates-markdown-page-index-tsx" */),
  "component---src-templates-topic-page-index-tsx": () => import("./../../../src/templates/TopicPage/index.tsx" /* webpackChunkName: "component---src-templates-topic-page-index-tsx" */)
}

